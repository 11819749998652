<template>
  <b-card-normal title="Dil Ayarları" class="rounded-0" :showLoading="show">
    <template v-slot:body>
      <b-form @submit.stop.prevent="onSubmit" @reset.prevent="resetForm" autocomplete="off">
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group :label="$t('global.aktifDiller')" description="Aktif Olacak Dilleri Belirleyiniz.">
              <v-select
                v-model="form.diller"
                :options="sabit_diller"
                :reduce="(sabit) => sabit.lang"
                label="title"
                multiple
                :clearable="false"
                @input="handlerDilChange"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Varsayılan" description="Varsayılan Dili Belirleyiniz.">
              <v-select
                v-model="form.varsayilan"
                :options="form.diller"
                :reduce="(dil) => dil.lang"
                label="title"
                :clearable="false"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="pt-1">
          <b-col cols="12" md="6" lg="6">
            <b-button squared block type="submit" variant="primary">
              <feather-icon icon="CheckSquareIcon" class="mr-50" />
              <span class="align-middle">{{ form._id == null ? $t('global.kaydet') : $t('global.guncelle') }}</span>
            </b-button>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <b-button squared block>
              <feather-icon icon="XSquareIcon" class="mr-50" />
              <span class="align-middle">{{ $t('global.iptal') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
  </b-card-normal>
</template>

<script>
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import vSelect from 'vue-select';
import { diller } from '@core/mixins/ui/diller';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
export default {
  components: {
    BCardNormal,
    vSelect,
  },
  mixins: [diller],
  data: () => ({
    show: false,
    form: {
      _id: null,
      diller: [],
      varsayilan: null,
    },
  }),
  created() {
    this.handlerGetData();
  },
  methods: {
    async handlerGetData() {
      this.show = true;
      this.$store.dispatch('dilAyarlariGetir').then((res) => {
        if (res.data.success == true && res.data.data != null) {
          this.form = res.data.data;
        }
      });
      this.show = false;
    },
    handlerDilChange(event) {
      let selectLang = [];
      event.forEach((element) => {
        selectLang.push(this.sabit_diller.find((x) => x.lang == element));
      });
      this.form.diller = selectLang;
    },
    onSubmit() {
      this.show = true;
      this.$store
        .dispatch('dilAyarlariEkle', this.form)
        .then((res) => {
          if (res.data.success === true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: res.data.message,
              },
            });
            this.show = false;
          }
        })
        .catch((err) => {
          if (err.data.success == false) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Hata`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text: err.data.message,
              },
            });
            this.show = false;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
